<template>
  <v-dialog v-model="deviceDebugInfoDialog" persistent max-width="400px">
    <DialogCard v-if="deviceDebugInfoDialog" modalIcon="bug-check-outline" modalTitle="Debug Info">
      <template #header>
        <v-btn icon x-small @click="close_device_debug_info_dialog()">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>

      <template #body>
        <section v-if="!isLoading">
          <div v-if="cellularInfo">
            <div>
              <h4>Network</h4>
              <div>MCC: {{ cellularInfo.MCC }}</div>
              <div>MNC: {{ cellularInfo.MNC }}</div>
              <div>PLMN: {{ cellularInfo.PLMN }}</div>
              <div>Roam: {{ cellularInfo.Roam }}</div>
              <div>5GPCI: {{ cellularInfo["5GPCI"] }}</div>
              <div>CellID: {{ cellularInfo.CellID }} </div>
              <div>LAC: {{ cellularInfo.LAC }}</div>
            </div>
            <div>
              <h4>Signal</h4>
              <div>5G RSRP: {{ cellularInfo["5GRSRP"] }}</div>
              <div>5G RSRQ: {{ cellularInfo["5GRSRQ"] }}</div>
              <div>5G SNR: {{ cellularInfo["5GSNR"] }}</div>
              <div>4G/3G RSRP: {{ cellularInfo.RSRP }}</div>
              <div>4G/3G RSRQ: {{ cellularInfo.RSRQ }}</div>
              <div>4G/3G SNR: {{ cellularInfo.SNR }}</div>
            </div>
    
            <div>
              <h4>Band / Bandwidth</h4>
              <div>B{{ cellularInfo.Band }}: {{ cellularInfo.BandWidth }}Mhz</div>
            </div>
            
            <div>
              <h4>Channel</h4>
              <div>DL: {{ cellularInfo.DLChannel }}</div>
              <div>UL: {{ cellularInfo.ULChannel }}</div>
            </div>
    
            <div>
              <h4>Power</h4>
              <div>Tx: {{ cellularInfo.TxPower }}dbm</div>
            </div>
          </div>
          <div v-else class="placeholder-container">
            <div class="d-flex align-center"><v-icon class="mr-1">mdi-note-off</v-icon>No Data...</div>
          </div>
        </section>
        <section v-else class="placeholder-container">
          <div class="grey--text"><i class="fas fa-spinner fa-spin mr-1" style="color: #949494"></i> Loading...</div>
        </section>
      </template>

      <template #footer>
        <v-btn small @click="close_device_debug_info_dialog()" class="button tabledata--text mr-2">Ok</v-btn>
      </template>
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/Slots/DialogCard.vue";
import { getCellularData } from "@/services";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    DialogCard,
  },
  props: {
    deviceDebugInfoDialog: Boolean,
  },
  watch: {
    deviceDebugInfoDialog: {
      handler(newVal){
        if(newVal) this.getCellularInfo();
        else{
          this.isLoading = true;
          this.cellularInfo = null;
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      isLoading: true,
      cellularInfo: null,
    }
  },
  computed: {
    ...mapGetters(["getterGetDeviceConfigurations"]),
    deviceId() {
      return this.getterGetDeviceConfigurations.router_id;
    },
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    close_device_debug_info_dialog(){
      this.$emit("close");
    },
    async getCellularInfo(){
      this.isLoading = true;
      try {
        this.cellularInfo = await getCellularData(this.deviceId);
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.isLoading = false;
      }
    }
  },
}
</script>

<style scoped>
  .placeholder-container {
    min-height: 484px;
    display: grid;
    place-items: center;
  }
</style>