<template>
  <v-form ref="form" class="pa-5">
    <h3 class="blue--text text--darken-1 mb-4">MAC Filtering</h3>

    <div>
      <v-switch ref="FilterSet" class="form-text-box d-inline-block" dense v-model="FilterSet" hide-details :disabled="MACFilteringRules.length === 0" @click="mixinUpdateFormField('macFiltering.FilterSet', `${configPath}.FilterSet`, FilterSet ? 1 : 0)">
        <template v-slot:label>
          <div>Allow only users on this list</div>
        </template>
      </v-switch>
    </div>

    <v-row class="mb-4" no-gutters>
      <v-col cols="10">
        <v-text-field ref="MACFilteringRules" class="list-proxy-text-field" v-model="MACFilteringRules" :rules="MACFilteringRulesValidationRule()" hidden></v-text-field>
      </v-col>
      <v-col class="d-flex justify-center" cols="2">
        <v-btn small @click="open_mac_filtering_rules_dialog('add')" class="button tabledata--text mr-2">Add</v-btn>
      </v-col>
    </v-row>

    <v-data-table show-select single-select dense :items="MACFilteringRules" :headers="MACFilteringRulesHeaders">

      <template v-slot:item.data-table-select="{ item, index }">
        <v-switch :input-value="item.ActiveRule" @click.stop="change_mac_filtering_rules_enable_state(item, index)" hide-details style="margin: auto"></v-switch>
      </template>

      <template v-slot:[`item.MACAddress`]="{ item }">
        {{ mixinMACAddressReturn(item.MACAddress) }}
      </template>

      <template v-slot:[`item.Actions`]="{ item, index }">
        <v-menu bottom left content-class="menu-offset">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="open_mac_filtering_rules_dialog('edit', item, index)">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteFromList(item, index)">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <!-- other form fields -->
    <MACFilteringRulesDialog :MACFilteringRulesDialog="MACFilteringRulesDialog" :MACFilteringRulesDialogMode="MACFilteringRulesDialogMode" :MACFilteringRules="MACFilteringRules" @close="close_mac_filtering_rules_dialog" />
    <DeleteDialog :deleteDialog="deleteDialog" :index="deleteIndex" @close="close_delete_dialog" />
  </v-form>
</template>

<script>
import MACFilteringRulesDialog from "./SubDialogs/MACFilteringRulesDialog.vue";
import DeleteDialog from "../SharedComponents/DeleteDialog.vue";

import { mapGetters, mapMutations } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  components: {
    MACFilteringRulesDialog,
    DeleteDialog
  },
  data() {
    return {
      MACFilteringRulesHeaders: [
        { text: "Rule Name", align: "start", sortable: true, class: "headerColor white--text ", value: "RuleName" },
        { text: "MAC Address", sortable: true, class: "headerColor white--text ", value: "MACAddress" },
        { text: "Actions", class: "headerColor white--text ", readOnly: true, value: "Actions" },
      ],

      FilterSet: false,
      MACFilteringRules: [], // your table data

      configPath: "configuration_module.MACAddressFiltering.Value",

      MACFilteringRulesDialog: false,

      // mode: contains string that determines mode of the dialog "edit" or "add" mode
      // item: if the dialog is in edit mode, this is the selected item object
      MACFilteringRulesDialogMode: {},

      // for confirming deletion in the list
      deleteDialog: false,
      deleteIndex: 0
    };
  },
  created() {
    this.initMACAddressFiltering();
  },
  watch: {
    MACFilteringRules: {
      handler(newVal) {
        this.$nextTick(() => {
          this.mixinUpdateFormField("macFiltering.MACFilteringRules", `${this.configPath}.MACFilteringRules`, this.MACFilteringRules);
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getterGetNewDeviceConfigurations"]),
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    initMACAddressFiltering() {
      const defaultMACAddressFiltering = this.getterGetNewDeviceConfigurations.configuration_module.MACAddressFiltering.Value;

      this.FilterSet = defaultMACAddressFiltering.hasOwnProperty("FilterSet") ? (defaultMACAddressFiltering.FilterSet === 1 ? true : false) : false;
      this.MACFilteringRules = defaultMACAddressFiltering.hasOwnProperty("MACFilteringRules") ? [...defaultMACAddressFiltering.MACFilteringRules] : [];

      this.$nextTick(() => {
        this.mixinValidateForm("macFiltering", "macFiltering");
      });
    },
    MACFilteringRulesValidationRule() {
      return [
        value => this.MACFilteringRules.length <= 20 || "There cannot be more than 20 MAC address filtering rules"
      ];
    },

    change_mac_filtering_rules_enable_state(item, itemIndex){
      this.MACFilteringRules.forEach((listItem, listIndex) => {
        if(itemIndex === listIndex){
          listItem.ActiveRule = item.ActiveRule === 0 ? 1 : 0;
        }
      })
      this.setFilterEnableBasedOnRuleList();
    },
    setFilterEnableBasedOnRuleList() {
      // Disable the whole list if all items in the list are disabled
      if(this.MACFilteringRules.every(listItem => listItem.ActiveRule === 0)) {
        this.FilterSet = false;
        this.$nextTick(() => {
          this.mixinUpdateFormField('macFiltering.FilterSet', `${this.configPath}.FilterSet`, 0);
        });
      }
      if(!this.FilterSet && this.MACFilteringRules.some(listItem => listItem.ActiveRule === 1)) {
        this.FilterSet = true;
        this.$nextTick(() => {
          this.mixinUpdateFormField('macFiltering.FilterSet', `${this.configPath}.FilterSet`, 1);
        });
      }
    },
    deleteFromList(item, index) {
      this.deleteIndex = index;
      this.deleteDialog = true;
    },
    close_delete_dialog(deleteObj){
      this.deleteDialog = false;
      if(deleteObj.mode === 1){
        this.MACFilteringRules.splice(deleteObj.index, 1);
        // Change "Index" property to proper value that represents index
        this.MACFilteringRules.forEach((listItem, index) => {
          listItem.Index = index;
        })
      }
      this.setFilterEnableBasedOnRuleList();
    },
    // a method for both edit and add
    open_mac_filtering_rules_dialog(mode, item, index) {
      if (this.MACFilteringRules.length >= 20 && mode === "add") {
        this.triggerSnackBar("error", "You cannot have more than 20 MAC address filtering rules.");
        return;
      }
      this.MACFilteringRulesDialog = true;
      this.MACFilteringRulesDialogMode = {
        mode,
      };
      if (mode === "edit") {
        this.MACFilteringRulesDialogMode.item = item;
        this.MACFilteringRulesDialogMode.index = index;
      }
    },
    // status = 1 => something changed and the apn list has to be updated
    // status = 0 => nothing changed and the dialog can just close down
    close_mac_filtering_rules_dialog(dialogObj) {
      if (dialogObj.status === 1) {
        if (dialogObj.mode === "edit") {
          this.$set(this.MACFilteringRules, dialogObj.index, dialogObj.item);
        } else if (dialogObj.mode === "add") {
          this.MACFilteringRules.push(dialogObj.item);
        }
        this.setFilterEnableBasedOnRuleList();
      }
      this.MACFilteringRulesDialog = false;
    },
  },
};
</script>

<style scoped>
.list-proxy-text-field ::v-deep .v-input__control .v-input__slot::before {
  border: 0 !important;
}
</style>
