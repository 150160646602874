<template>
  <DeviceStatusCardTemplate :componentList="componentList" :maxPerRow="maxPerRow">
    <template v-slot:Battery>
      <strong>BATTERY</strong>
      <Battery 
        :deviceBatteryLevel="deviceBatteryLevel" 
        class="widget"
      />
      <div class="footer"></div>
    </template>
    <template v-slot:DataUsage>
      <strong>DATA USAGE</strong>
      <DataUsage 
        :deviceDataUsage="deviceDataUsage" 
        :deviceMonthlyDataUsageLimit="deviceMonthlyDataUsageLimit" 
        class="widget" 
      />
      <div class="footer">
        <v-btn plain class="text-decoration-underline text-none" @click="dataUsageChartDialog = true" color="deep-purple darken-4">
          <span v-if="deviceMonthlyDataUsageLimit">{{ deviceMonthlyDataUsageLimit }} GB Per Month</span>
          <span v-else>View Details</span>
        </v-btn>
      </div>
      <DeviceDataUsage v-model:dataUsageChartDialog="dataUsageChartDialog" />
    </template>
    <template v-slot:Signal>
      <strong>SIGNAL</strong>
      <Signal 
        :deviceSignal="deviceSignal" 
        :connectionType="connectionType" 
        class="widget"
      />
      <div class="footer"></div>
    </template>
    <template v-slot:CPUUsage>
      <strong>CPU USAGE</strong>
      <CPUUsage 
        :cpuUsage="deviceCPUUsage" 
        class="widget"
      />
      <div class="footer"></div>
    </template>
    <template v-slot:MemoryUsage>
      <strong>MEMORY USAGE</strong>
      <MemoryUsage 
        :memoryUsage="deviceMemoryUsage" 
        class="widget"
      />
      <div class="footer"></div>
    </template>
    <template v-slot:Ethernet>
      <strong>Ethernet</strong>
      <Ethernet 
        :deviceLANStatus="deviceLANStatus"
        class="widget"
      />
      <div class="footer"></div>
    </template>
  </DeviceStatusCardTemplate>
</template>

<script>
import DeviceStatusCardTemplate from "./StatusWidgets/DeviceStatusCardTemplate.vue";

import Battery from "./StatusWidgets/Battery.vue";
import DataUsage from "./StatusWidgets/DataUsage.vue";
import Signal from "./StatusWidgets/Signal.vue";
import CPUUsage from "./StatusWidgets/CPUUsage.vue";
import MemoryUsage from "./StatusWidgets/MemoryUsage.vue";
import Ethernet from "./StatusWidgets/Ethernet.vue";
import DeviceDataUsage from "./SubDialogs/DeviceDataUsage.vue";


import { mapGetters } from "vuex";
import { router_model_utils } from "@/mixins/routerModelUtils.js";


export default {
  name: "DeviceStatusCard",
  mixins: [router_model_utils],
  components: {
    DeviceStatusCardTemplate,
    Battery,
    DataUsage,
    Signal,
    CPUUsage,
    MemoryUsage,
    Ethernet,
    DeviceDataUsage,
  },
  data: () => ({
    dataUsageChartDialog: false,
  }),
  computed: {
    ...mapGetters([ 'getterGetDeviceConfigurations', 'getterGetDeviceWANStatus' ]),
    deviceModel() {
      return this.getterGetDeviceConfigurations.router_details.router_model;
    },
    deviceBatteryLevel() {
      return this.getterGetDeviceConfigurations.router_details.battery_level ? this.getterGetDeviceConfigurations.router_details.battery_level : 0;
    },
    deviceDataUsage() {
      return this.getterGetDeviceConfigurations.router_details.data_usage ? Number(this.getterGetDeviceConfigurations.router_details.data_usage) : 0;
    },
    deviceMonthlyDataUsageLimit() {
      if (!this.getterGetDeviceConfigurations.configuration_module.Usage) return 0;
      return this.getterGetDeviceConfigurations.configuration_module.Usage.Value.MonthlyLimitAlert ? this.getterGetDeviceConfigurations.configuration_module.Usage.Value.MonthlyLimit / 1024 : 0;
    },
    deviceSignal() {
      return this.getterGetDeviceConfigurations.router_details.signal;
    },
    deviceLANStatus() {
      return this.getterGetDeviceWANStatus ? this.getterGetDeviceWANStatus.LANStatus[0] : {};
    },
    deviceConnectedStatus() {
      return this.getterGetDeviceConfigurations.configuration_module.ConnectedStatus;
    },
    deviceCPUUsage() {
      return this.getterGetDeviceConfigurations.router_details.cpu_usage ? this.getterGetDeviceConfigurations.router_details.cpu_usage : 0;
    },
    deviceMemoryUsage() {
      return this.getterGetDeviceConfigurations.router_details.memory_usage ? this.getterGetDeviceConfigurations.router_details.memory_usage : 0;
    },
    connectionType() {
      return this.deviceConnectedStatus ? this.convert_connectedStatus(this.deviceConnectedStatus.Value.ConnectionType) : "";
    }, 
    componentList() {
      const componentList = [];
      if (this.shouldShowBatteryLevel(this.deviceModel)) 
        componentList.push("Battery");
      if (true) // Data Usage should be visible on all models
        componentList.push("DataUsage");
      if (this.shouldShowSignal(this.deviceModel))
        componentList.push("Signal");
      if (this.shouldShowCPUUsage(this.deviceModel))
        componentList.push("CPUUsage");
      if (this.shouldShowMemoryUsage(this.deviceModel))
        componentList.push("MemoryUsage");
      if (this.shouldShowEthernetLinkStatus(this.deviceModel))
        componentList.push("Ethernet");
      return componentList;
    }, 
    maxPerRow() {
      if (this.deviceModel === "FX20" && this.$vuetify.breakpoint.xsOnly) return 2;
      return 4;
    }
  },
}
</script>

<style scoped>
.widget {
  height: 4rem;
  margin-top: 1rem;
}

/* This footer is necessary to make sure each widget is the same height 
   when the container resizes to two rows on small screens.
   If there is a more elegant way to do this, I'd welcome the change */
.footer { 
  height: 2rem;
}
</style>