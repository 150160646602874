import { getDeviceWANStatus, getDeviceDHCPClients, getDeviceConfigurations, setDeviceConfigurations, setGroupConfigurations } from "@/services";
import { jsonDeepDiffList } from "json-deep-diff-list";
import Vue from 'vue';

function updateNestedObject(targetObj, path, value, deleteFlag = false) {
  // 변경될 json 구조체 대상
  let schema = targetObj;
  // "configuration_module.TimeOfDayAccess.Value.ScheduleList.0.Index"와 같은 Path를
  // [ "configuration_module", "TimeOfDayAccess", "Value", "ScheduleList", "0", "Index"]로 변경
  const splitPathArray = path.split(".");
  const len = splitPathArray.length;

  // For loop 을 통해 nesting된 property까지 searching함
  for (let i = 0; i < len - 1; i++) {
    const elem = splitPathArray[i];
    if (!schema.hasOwnProperty(elem)) {
      // 특정 property가 없다면 중간에 생성함
      // +splitPathArray -> 앞에 +를 통해 character를 integer로 변환
      schema[elem] = Number.isInteger(+splitPathArray[i + 1]) ? [] : {};
    }
    // 다음 path nest로 넘어감
    schema = schema[elem];
  }

  // deleteFlag가 true일 경우, 해당 property를 삭제함
  if (deleteFlag) {
    if (Number.isInteger(+splitPathArray[len - 1])) {
      // 변경되는 대상이 array라면 "splice"함수를 통해 array중 한 요소만 삭제함
      const index = parseInt(splitPathArray[len - 1], 10);
      if (Array.isArray(schema)) {
        schema.splice(index, 1);
      } else {
        // path가 array인데 "schema"(변경될 구조체)가 array가 아니면 오류를 표출함
        console.warn("Attempted to delete an array element from a non-array structure.");
      }
    } else {
      // array가 아니면 그냥 삭제
      delete schema[splitPathArray[len - 1]];
    }
  } else {
    // 최종적으로 값을 정해서 붙임 
    // path가 array일 경우 아래와 같이 변경
    if (Number.isInteger(+splitPathArray[len - 1])) {
      schema[parseInt(splitPathArray[len - 1], 10)] = value;
    } else {
      // path가 array가 아닌 경우
      schema[splitPathArray[len - 1]] = value;
    }
  }
}

// This is not used and may be removed later
function parseDiff(diffArray) {
  const parsedDiff = {
    updated_list: [],
    deleted_list: []
  }
  diffArray.forEach(item => {
    if(item.type === "delete") {
      parsedDiff.deleted_list.push({ key: item.path });
    } else {
      parsedDiff.updated_list.push({ value: item.after, key: item.path});
    }
  })
  return parsedDiff;
}

function setReactive(target, source) {
  Object.keys(source).forEach(key => {
    const value = source[key];
    
    if (Array.isArray(value)) {
      Vue.set(target, key, []);
      value.forEach((item, index) => {
        if (typeof item === 'object' && item !== null) {
          Vue.set(target[key], index, Array.isArray(item) ? [] : {});
          setReactive(target[key][index], item);
        } else {
          Vue.set(target[key], index, item);
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      Vue.set(target, key, {});
      setReactive(target[key], value);
    } else {
      Vue.set(target, key, value);
    }
  });
}

const state = {
  deviceInfoIsLoaded: true,
  isSendingDeviceConfig: false,
  currentDeviceUserEmail: "",
  currentDeviceModel: null,
  currentDeviceModelAlias: null,
  deviceWANStatus: null,
  deviceDHCPClients: null,
  originalDeviceConfigurations: null,
  newDeviceConfigurations: null,
  testConfig: null,
  configurationsDiff: [],
};

const getters = {
  getterGetCurrentDeviceUserEmail: (state) => {
    return state.currentDeviceUserEmail;
  },
  getterGetCurrentDeviceModel: (state) => {
    return state.currentDeviceModel;
  },
  getterGetCurrentDeviceModelAlias: (state) => {
    return state.currentDeviceModelAlias;
  },
  getterGetDeviceWANStatus: (state) => {
    return state.deviceWANStatus;
  },
  getterGetDeviceDHCPClients: (state) => {
    return state.deviceDHCPClients;
  },
  getterGetDeviceConfigurations: (state) => {
    return state.originalDeviceConfigurations;
  },
  getterGetNewDeviceConfigurations: (state) => {
    return state.newDeviceConfigurations;
  },
  getterGetDeviceInfoIsLoaded: (state) => {
    return state.deviceInfoIsLoaded;
  },
  getterGetIsSendingDeviceConfig: (state) => {
    return state.isSendingDeviceConfig;
  },
  getterGetConfigurationsDiff: (state) => {
    return state.configurationsDiff;
  },
  // This is specifically for checking internet on/off status for internet suspend feature
  getterGetConnectionAllowed: (state) => {
    const scheduleList = state.originalDeviceConfigurations.configuration_module.TimeOfDayAccess ? state.originalDeviceConfigurations.configuration_module.TimeOfDayAccess.Value.ScheduleList : [];
    let isConnectionOn = true;
    if(scheduleList.length === 1) isConnectionOn = !(scheduleList[0].Enable && scheduleList[0].StartTime === 2 && scheduleList[0].EndTime === 3);
    return isConnectionOn;
  }
};

const mutations = {
  mutationSetCurrentDeviceModel: (state, payload) => {
    state.currentDeviceModel = payload;
  },
  mutationSetCurrentDeviceModelAlias: (state, payload) => {
    state.currentDeviceModelAlias = payload;
  },
  mutationSetDeviceWANStatus: (state, payload) => {
    state.deviceWANStatus = payload;
  },
  mutationSetDeviceDHCPClients: (state, payload) => {
    state.deviceDHCPClients = payload;
  },
  mutationSetDeviceConfigurations: (state, payload) => {
    state.originalDeviceConfigurations = JSON.parse(JSON.stringify(payload));
    state.newDeviceConfigurations = JSON.parse(JSON.stringify(payload));

    // console.log(JSON.parse(JSON.stringify(state.originalDeviceConfigurations)));
  },
  mutationSetDeviceInfoIsLoaded: (state, payload) => {
    state.deviceInfoIsLoaded = payload;
  },
  mutationSetIsSendingDeviceConfig: (state, payload) => {
    state.isSendingDeviceConfig = payload;
  },
  mutationUpdateConfigurations: (state, { path, value, deleteFlag }) => {
    updateNestedObject(state.newDeviceConfigurations, path, value, deleteFlag);
    // console.log(JSON.parse(JSON.stringify(state.newDeviceConfigurations.configuration_module.MISC.Value)));
    // console.log(JSON.parse(JSON.stringify(state.newDeviceConfigurations.configuration_module.WirelessLAN.Value)));
  },
  mutationDiffConfiguration: (state, payload) => {
    state.configurationsDiff = jsonDeepDiffList(state.originalDeviceConfigurations, state.newDeviceConfigurations);
    // console.log("Diffed Array in Vuex: ", JSON.parse(JSON.stringify(state.configurationsDiff)));
  },
  mutationResetDeviceConfigurationDiff: (state, payload) => {
    // (state.originalDeviceConfigurations = null), (state.newDeviceConfigurations = null), 
    (state.configurationsDiff = []);
  },
  mutationSetCurrentDeviceUserEmail: (state, payload) => {
    state.currentDeviceUserEmail = payload;
  },
};

const actions = {
  actionGetSingleDeviceInfo: async (context, deviceId) => {
    // This state is added to make sure that child components are in sync with parent component
    // If it is set to true, the child component shouldn't render until set to false
    context.commit("mutationSetDeviceInfoIsLoaded", false);
    try {
      const deviceWANStatus = await getDeviceWANStatus(deviceId);
      const deviceDHCPClients = await getDeviceDHCPClients(deviceId);
      context.commit("mutationSetDeviceWANStatus", deviceWANStatus);
      context.commit("mutationSetDeviceDHCPClients", deviceDHCPClients);
      // console.log(JSON.parse(JSON.stringify(deviceConfiguration)));
    } catch (err) {
      // removed throw err
    } finally {
      // set to false once all async operations are finished
      context.commit("mutationSetDeviceInfoIsLoaded", true);
    }
  },
  actionGetDeviceConfiguration: async (context, deviceId) => {
    try {
      const deviceConfiguration = await getDeviceConfigurations(deviceId);
      context.commit("mutationSetDeviceConfigurations", deviceConfiguration);
    } catch (err) {
      throw err;
    }
  },
  // post device configs
  actionSetDeviceConfiguration: async (context, payload) => {
    // console.log(payload);
    try {
      const deviceConfiguration = await setDeviceConfigurations(payload);
    } catch (err) {
      throw err;
    }
  },
  // post group configs
  actionSetGroupConfiguration: async (context, payload) => {
    try {
      const deviceConfiguration = await setGroupConfigurations(payload);
    } catch (err) {
      throw err;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
