export const get_router_list = /* GraphQL */ `
  query Get_router_list($input: GetRouterListInput) {
    get_router_list(input: $input)
  }
`;
export const get_connected_status_for_router = /* GraphQL */ `
  query Get_connected_status_for_router(
    $input: GetConnectedStatusForRouterInput
  ) {
    get_connected_status_for_router(input: $input)
  }
`;

export const get_cellular_data = /* GraphQL */ `
  query get_router_cellular(
    $input: GetRouterCellularInput
  ) {
    get_router_cellular(input: $input)
  }
`;

export const list_dhcp_client_for_particular_router = /* GraphQL */ `
  query List_dhcp_client_for_particular_router(
    $input: ListDhcpClientForParticularRouterInput
  ) {
    list_dhcp_client_for_particular_router(input: $input)
  }
`;

export const get_configuration_of_router = /* GraphQL */ `
  query Get_configuration_of_router($input: GetConfigurationOfRouterInput) {
    get_configuration_of_router(input: $input)
  }
`;

export const get_router_details_for_the_particular_user = /* GraphQL */ `
  query Get_router_details_for_the_particular_user(
    $input: GetRouterDetailsForTheParticularUserInput
  ) {
    get_router_details_for_the_particular_user(input: $input)
  }
`;

export const request_query_to_router = /* GraphQL */ `
query request_query_to_router(
    $input: RequestQueryToRouterInput
  ) {
    request_query_to_router(input: $input)
  }
`;

export const get_router_check_exists_or_not = /* GraphQL */ `
  query Get_router_check_exists_or_not($input: GetRouterCheckExistsOrNotInput) {
    get_router_check_exists_or_not(input: $input)
  }
`;