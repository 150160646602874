<template>
  <!-- ref="form" must be present for all forms for validation algorithm to work correctly -->
  <v-form ref="form" class="pa-5">
    <h3 class="blue--text text--darken-1 mb-4">Main Wi-Fi</h3>

    <v-select ref="wifiBands" class="form-text-box" outlined dense label="Wi-Fi Band" :items="mainWifiBandsList()" v-model="wifiBands" @input="mixinUpdateFormField('mainWifi.wifiBands', `${configPath}.MainWiFi.RadioBand`, wifiBands)">
      <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'MainWiFi.RadioBand')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <!-- autocomplete="new-password" is a hack to prevent browsers such as Chrome from autocompleting or bringing up password management suggestions -->
    <v-text-field ref="wifiName" class="form-text-box" outlined dense label="Wi-Fi SSID" placeholder="Enter your Wi-Fi name" v-model="wifiName" :rules="mixinWifiNameRule()" autocomplete="new-password" @blur="mixinUpdateFormField('mainWifi.wifiName', `${configPath}.MainWiFi.WiFiName`, wifiName)">
      <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'MainWiFi.WiFiName')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-text-field>

    <!-- <v-text-field ref="wifiPassword" v-show="wifiAuth !== 0" class="form-text-box" outlined dense label="Wi-Fi Password" placeholder="Enter your Wi-Fi password" @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text': 'password'" v-model="wifiPassword" :rules="mixinWifiPasswordRule()" autocomplete="new-password" @blur="mixinUpdateFormField('mainWifi.wifiPassword', `${configPath}.MainWiFi.WiFiPassword`, wifiPassword)"></v-text-field> -->

    <v-text-field ref="wifiPassword" v-show="wifiAuth !== 0" class="form-text-box" outlined dense label="Wi-Fi Password" placeholder="Enter your Wi-Fi password" @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" v-model="wifiPassword" :rules="mixinWifiPasswordRule()" autocomplete="new-password" @blur="mixinUpdateFormField('mainWifi.wifiPassword', `${configPath}.MainWiFi.WiFiPassword`, wifiPassword)">
      <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'MainWiFi.WiFiPassword')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-text-field>

    <!-- <v-select ref="wifiEncryption" v-show="wifiAuth !== 0" class="form-text-box" outlined dense label="Encryption Method" :items="mixinGetWifiEncryptionList()" v-model="wifiEncryption" @input="mixinUpdateFormField('mainWifi.wifiEncryption', `${configPath}.MainWiFi.EncryptionMethod`, wifiEncryption)"></v-select> -->

    <v-select ref="wifiAuth" class="form-text-box" outlined dense label="Authentication Method" :items="mixinGetWifiAuthList()" v-model="wifiAuth" @input="mixinUpdateFormField('mainWifi.wifiAuth', `${configPath}.MainWiFi.AuthenticationMethod`, wifiAuth)">
      <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'MainWiFi.AuthenticationMethod')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <div class="form-text-box form-two-column-grid">
      <v-select ref="mainWifiConnections" class="form-text-box" label="Main Wi-Fi Connections Allowed" outlined dense hide-details :items="mainWifiConnectionsList" v-model="mainWifiConnections" @input="mixinUpdateFormField('mainWifi.mainWifiConnections', `${configPath}.MainWiFi.MaximumConnections`, mainWifiConnections)">
        <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'MainWiFi.MaximumConnections')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-select>

      <v-select ref="guestWifiConnections" class="form-text-box" :label="guestWifiEnable ? 'Guest Wi-Fi Connections Allowed' : 'Guest Wi-Fi Disabled'" outlined dense hide-details :items="guestWifiConnectionsList" v-model="guestWifiConnections" :disabled="!guestWifiEnable" @input="mixinUpdateFormField('mainWifi.guestWifiConnections', `${configPath}.Guest3WiFi.MaximumConnections`, guestWifiConnections)">
        <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'Guest3WiFi.MaximumConnections')" slot="prepend">
          <UnchangedGroupConfigIcon />
        </template>
      </v-select>
    </div>

    <div class="ml-3 mt-3 mb-5">Available Connections: {{ availableConnections }}/{{ maxConnections }}</div>

    <v-select ref="inactiveTime" class="form-text-box" label="Inactive Time" outlined dense :items="mixinGetInactiveTimeList()" v-model="inactiveTime" @input="mixinUpdateFormField('mainWifi.inactiveTime', `${configPath}.InActiveTime`, inactiveTime)">
      <template v-if="mixinCheckGroupConfigChange('WirelessLAN', 'InActiveTime')" slot="prepend">
        <UnchangedGroupConfigIcon />
      </template>
    </v-select>

    <div class="d-flex align-center mt-2">
      <div style="padding-right: 9px" v-if="mixinCheckGroupConfigChange('WirelessLAN', 'MainWiFi.PrivacySeparator')">
        <UnchangedGroupConfigIcon />
      </div>
      <v-switch ref="privacySeparator" class="form-text-box d-inline-block" dense v-model="privacySeparator" hide-details @click="mixinUpdateFormField('mainWifi.privacySeparator', `${configPath}.MainWiFi.PrivacySeparator`, privacySeparator ? 1 : 0)">
        <template v-slot:label>
          <div>Privacy Separator</div>
        </template>
      </v-switch>
    </div>

    <div class="d-flex align-center mt-4">
      <div style="padding-right: 9px" v-if="mixinCheckGroupConfigChange('WirelessLAN', 'MainWiFi.SSIDStealth')">
        <UnchangedGroupConfigIcon />
      </div>
      <v-switch ref="ssidStealth" class="form-text-box d-inline-block" dense v-model="ssidStealth" hide-details @click="mixinUpdateFormField('mainWifi.ssidStealth', `${configPath}.MainWiFi.SSIDStealth`, ssidStealth ? 1 : 0)">
        <template v-slot:label>
          <div>SSID Stealth</div>
        </template>
      </v-switch>
    </div>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils.js";
import { mixin_list_table_utils } from "@/mixins/listTableUtils.js";
import UnchangedGroupConfigIcon from "@/components/DeviceConfigurations/DeviceConfigurationComponents/SharedComponents/UnchangedGroupConfigIcon.vue";

export default {
  mixins: [mixin_form_validation_utils, mixin_list_table_utils],
  components: {
    UnchangedGroupConfigIcon,
  },
  data() {
    return {
      guestWifiEnable: false,
      maxConnections: 32,
      mainWifiConnectionsList: [],
      guestWifiConnectionsList: [],

      // declare/initialize "v-model"ed variables
      wifiBands: -1,
      wifiName: "",
      wifiPassword: "",
      showPassword: false,
      wifiAuth: -1,
      // wifiEncryption: -1,
      inactiveTime: -1,
      mainWifiConnections: -1,
      guestWifiConnections: -1,
      privacySeparator: false,
      ssidStealth: false,
      configPath: "configuration_module.WirelessLAN.Value",
    };
  },
  created() {
    // set values fetched from the database
    this.initMainWifi();
  },
  watch: {
    // Set allowed connection values when Guest Wi-Fi Enable state changes
    // Reinitialize everytime the configuration changes. This will keep WiFiBand up to date.
    // WiFiBand for the Guest Wi-Fi and Main Wi-Fi is mutually exclusive. One has to be 2.4GHz and other has to be 5.0GHz.
    "$store.state.device.newDeviceConfigurations": {
      handler() {
        this.setConnections();
        // this.initMainWifi();
      },
      deep: true,
    },
    wifiBands: {
      handler(newVal) {
        // If Main Wi-Fi and Guest Wi-Fi bands are equal to each other, automatically change Guest Wi-Fi band to the lowest band value that is not equal to Main Wi-Fi
        this.$nextTick(() => {
          const guestBand = this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value.Guest3WiFi.RadioBand
          if (newVal === guestBand) {
            const bandValueList = [0, 1, 2];
            const excludedGuestWiFiBandList = bandValueList.filter(band => band !== guestBand);
            const newGuestBandVal = Math.min(...excludedGuestWiFiBandList);
            this.mixinUpdateFormField("guestWifi.wifiBands", `${this.configPath}.Guest3WiFi.RadioBand`, newGuestBandVal);
          }
        });
      },
      immediate: true,
    },
    // fill in guest wi-fi connections list relative to main wifi connections value
    mainWifiConnections: {
      handler(newVal) {
        this.guestWifiConnectionsList = [];
        for (let i = 1; i <= this.maxConnections - newVal; i++) {
          if (i !== this.maxConnections) this.guestWifiConnectionsList.push(i);
        }
        this.$nextTick(() => {
          this.mixinUpdateFormField("mainWifi.mainWifiConnections", `${this.configPath}.TotalConnections`, this.mainWifiConnections + this.guestWifiConnections);
        });
      },
      immediate: true,
    },
    // vice versa
    guestWifiConnections: {
      handler(newVal) {
        this.mainWifiConnectionsList = [];
        for (let i = 1; i <= this.maxConnections - newVal; i++) {
          this.mainWifiConnectionsList.push(i);
        }
        this.$nextTick(() => {
          this.mixinUpdateFormField("mainWifi.guestWifiConnections", `${this.configPath}.TotalConnections`, this.mainWifiConnections + this.guestWifiConnections);
        });
      },
      immediate: true,
    },
    getterGetCurrentError: {
      handler(newVal) {
        if (newVal && newVal.path) {
          let errorMenu = newVal.path.split(".")[1];
          this.$nextTick(() => {
            if (this.$refs[errorMenu]) {
              // This small delay will make sure that the cursor gets focused in correctly
              setTimeout(() => {
                this.$refs[errorMenu].focus();
              }, 500);
            }
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getterGetNewDeviceConfigurations", "getterGetCurrentError"]),
    availableConnections() {
      return this.maxConnections - (this.mainWifiConnections + this.guestWifiConnections);
    },
  },
  methods: {
    // change wifi encryption value to 0 automatically if
    setEncryptionVal() {
      // if(this.wifiAuth === 0){
      //   this.wifiEncryption = 0;
      //   // set the wifi password to its last valid or original value
      //   this.wifiPassword = this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value.MainWiFi.WiFiPassword;
      //   this.$nextTick(()=> {
      //     // update the dependent fields values with valid values
      //     this.mixinUpdateFormField('mainWifi.wifiEncryption', `${this.configPath}.MainWiFi.EncryptionMethod`, this.wifiEncryption);
      //     this.mixinUpdateFormField('mainWifi.wifiPassword', `${this.configPath}.MainWiFi.WiFiPassword`, this.wifiPassword);
      //   })
      // } else if(this.wifiAuth !== 0 && this.wifiEncryption === 0) {
      //   this.wifiEncryption = 1;
      //   this.$nextTick(()=> {
      //     this.mixinUpdateFormField('mainWifi.wifiEncryption', `${this.configPath}.MainWiFi.EncryptionMethod`, this.wifiEncryption);
      //   })
      // }
    },
    mainWifiBandsList() {
      return [
        { text: "2.4GHz", value: 0 },
        { text: "5.0GHz", value: 1 },
        { text: "6.0GHz", value: 2 },
      ]
    },
    // intialize all fields with value from vuex
    initMainWifi() {
      const defaultWirlessLAN = this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value;
      const defaultMainWifi = defaultWirlessLAN.MainWiFi;
      const defaultGuestWifi = defaultWirlessLAN.Guest3WiFi;

      this.inactiveTime = defaultWirlessLAN.hasOwnProperty("InActiveTime") ? defaultWirlessLAN.InActiveTime : -1;

      this.wifiBands = defaultMainWifi.hasOwnProperty("RadioBand") ? defaultMainWifi.RadioBand : -1;
      this.wifiName = defaultMainWifi.hasOwnProperty("WiFiName") ? defaultMainWifi.WiFiName : "";
      this.wifiPassword = defaultMainWifi.hasOwnProperty("WiFiPassword") ? defaultMainWifi.WiFiPassword : "";
      this.wifiAuth = defaultMainWifi.hasOwnProperty("AuthenticationMethod") ? defaultMainWifi.AuthenticationMethod : "";
      // this.wifiEncryption = defaultMainWifi.hasOwnProperty("EncryptionMethod") ? defaultMainWifi.AuthenticationMethod : "";
      this.mainWifiConnections = defaultMainWifi.hasOwnProperty("MaximumConnections") ? defaultMainWifi.MaximumConnections : -1;
      this.guestWifiConnections = defaultGuestWifi.hasOwnProperty("MaximumConnections") ? defaultGuestWifi.MaximumConnections : -1;
      this.privacySeparator = defaultMainWifi.hasOwnProperty("PrivacySeparator") ? (defaultMainWifi.PrivacySeparator ? true : false) : false;
      this.ssidStealth = defaultMainWifi.hasOwnProperty("SSIDStealth") ? (defaultMainWifi.SSIDStealth ? true : false) : false;

      // This is a failsafe code where if both Radio bands are equal to each other, it will fall into a infinite loop caused by the watcher functions
      // Main Wi-Fi and Guest Wi-Fi Radio band should never be equal to each other
      if(defaultMainWifi.RadioBand === defaultGuestWifi.RadioBand){
        this.mixinUpdateFormField("guestWifi.wifiBands", `${this.configPath}.MainWiFi.RadioBand`, 0, false, true);
        this.mixinUpdateFormField("guestWifi.wifiBands", `${this.configPath}.Guest3WiFi.RadioBand`, 1, false, true);
      }
      this.$nextTick(() => {
        this.mixinValidateForm("mainWifi", "mainWifi");
      });
    },
    setConnections() {
      const defaultWirlessLAN = this.getterGetNewDeviceConfigurations.configuration_module.WirelessLAN.Value;
      // Change Max Connections Config
      this.guestWifiEnable = defaultWirlessLAN.hasOwnProperty("Guest3WiFiEnable") ? defaultWirlessLAN.Guest3WiFiEnable : -1;
      if (!this.guestWifiEnable) {
        this.guestWifiConnections = 0;
        this.$nextTick(() => {
          this.mixinUpdateFormField("mainWifi.guestWifiConnections", `${this.configPath}.Guest3WiFi.MaximumConnections`, this.guestWifiConnections);
        });
      } else if (this.guestWifiEnable && this.guestWifiConnections === 0) {
        this.guestWifiConnections = 1;
        this.$nextTick(() => {
          this.mixinUpdateFormField("mainWifi.guestWifiConnections", `${this.configPath}.Guest3WiFi.MaximumConnections`, this.guestWifiConnections);
          this.mixinUpdateFormField("mainWifi.mainWifiConnections", `${this.configPath}.MainWiFi.MaximumConnections`, this.mainWifiConnections);
        });
        if (this.mainWifiConnections === this.maxConnections) {
          this.mainWifiConnections = this.maxConnections - 1;
          this.$nextTick(() => {
            this.mixinUpdateFormField("mainWifi.mainWifiConnections", `${this.configPath}.MainWiFi.MaximumConnections`, this.mainWifiConnections);
          });
        }
      }

      // Set Wi-Fi radio band
      // This is here so that the radio band value changes when Guest Wi-Fi's Radio Band changes
      this.wifiBands = defaultWirlessLAN.MainWiFi.hasOwnProperty("RadioBand") ? defaultWirlessLAN.MainWiFi.RadioBand : -1;
    },
  },
};
</script>
